import axios from '@/plugins/axiosV2'

const http = axios(process.env.VUE_APP_API_TICKETS);

export default {
  obtener_areas() {
    return http.get(`/areas`);
  },

  obtener_area(id) {
    return http.get(`/areas/${id}`);
  },

  crear_area(payload) {
    return http.post(`/areas`, payload);
  },

  editar_area(id, payload) {
    return http.put(`/areas/${id}`, payload);
  },

  eliminar_area(id) {
    return http.delete(`/areas/${id}`);
  },

  // Usuarios
  agregar_usuario(id, payload) {
    return http.post(`/areas/${id}/usuarios`, payload);
  },

  editar_usuario(id, usuario, payload) {
    return http.put(`/areas/${id}/usuarios/${usuario}`, payload);
  },

  eliminar_usuario(id, usuario) {
    return http.delete(`/areas/${id}/usuarios/${usuario}`);
  },

  // Etapas
  agregar_etapa(id, payload) {
    return http.post(`/areas/${id}/etapas`, payload);
  },

  editar_etapa(id, etapa, payload) {
    return http.put(`/areas/${id}/etapas/${etapa}`, payload);
  },

  eliminar_etapa(id, etapa) {
    return http.delete(`/areas/${id}/etapas/${etapa}`);
  },

  // Requerimientos
  obtener_req(id, req) {
    return http.get(`/areas/${id}/requerimientos/${req}`);
  },

  crear_req(id, payload) {
    return http.post(`/areas/${id}/requerimientos`, payload);
  },

  editar_req(id, req, payload) {
    return http.put(`/areas/${id}/requerimientos/${req}`, payload);
  },

  eliminar_req(id, req) {
    return http.delete(`/areas/${id}/requerimientos/${req}`);
  }
}